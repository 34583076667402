<template>
  <div>
    <component :is="userData === undefined ? 'div' : 'b-card'">

      <div v-if="!userData || !eventsData" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <div v-if="userData">
        <h4>
          <feather-icon
            icon="UserIcon"
            size="18"
          /> &nbsp;
          Account
        </h4>
        <user-view-content
          :user-data="userData"
          class="mt-2 pt-75"
        />

        <div class="mt-2">
          <h4>
            <feather-icon
              icon="InfoIcon"
              size="18"
            /> &nbsp;
            Profile
          </h4>

          <user-view-profile-content
            :user-data="userData"
            class="mt-2 pt-75"
          />
        </div>

          <b-row class="mt-2">
            <b-col>
              <div style="float: left">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  @click="edit()"
                >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                /><span>Edit</span>
                </b-button>
              </div>
              <div style="float: right">
                <b-button size="md" variant="outline-primary" @click="back()">
                  <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                  /><span>Back</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

      </div>

    </component>

    <b-card v-if="userData">
        <profile-history :rows="userData.profiles" />
    </b-card>

    <b-card v-if="eventsData">
        <event-history :rows="eventsData" />
    </b-card>

  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner, BButton, BCol, BRow
} from 'bootstrap-vue'
import router from '@/router'
import UserViewContent from './UserViewContent.vue'
import UserViewProfileContent from './UserViewProfileContent.vue'
import ProfileHistory from './ProfileHistory.vue'
import EventHistory from './EventHistory.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    BButton,
    BCol,
    BRow,

    UserViewContent,
    UserViewProfileContent,
    ProfileHistory,
    EventHistory
  },
  data() {
    return {
      userData: null,
      eventsData: null
    }
  },
  methods: {
    edit() {
        this.$router.push('/users/' + this.userData.id + '/edit')
    },
    back() {
        this.$router.push('/users')
    },
    fetchEventsData() {
      const id = router.currentRoute.params.id

      const requestData = {
        user_id: id
      }

      this.$http.post('/admin/v1/events_by_user', requestData)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventsData = currentData
          }
      })
    },    
    fetchUserData() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/users/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.userData = {
              id: currentData.id,
              name: currentData.name,
              last_name: currentData.last_name,
              salutation: currentData.salutation,
              email: currentData.email,
              status: (currentData.email_verified_at !== null),
              email_verified_at: currentData.email_verified_at,
              roles: currentData.roles,
              role: (currentData.roles !== null && currentData.roles.length > 0) ? currentData.roles[0].name : null,
              company: currentData.company,
              profile: (currentData.profiles && currentData.profiles.length > 0) ? currentData.profiles[0] : null,
              profiles: currentData.profiles
            }
          }
      })
    }
  },
  created() {
    this.fetchUserData()
    this.fetchEventsData()
  },
}
</script>
