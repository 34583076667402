<template>
  <b-card title="Profile History">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTermProfile"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTermProfile }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Phone -->
        <span
          v-if="props.column.field === 'phone'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Home Address -->
        <span
          v-if="props.column.field === 'home_address'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Company -->
        <span
          v-if="props.column.field === 'company'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Brand Name -->
        <span
          v-if="props.column.field === 'brand_name'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Industry -->
        <span
          v-if="props.column.field === 'industry'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Industry -->
        <span
          v-if="props.column.field === 'company_size'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Company Address -->
        <span
          v-if="props.column.field === 'company_address'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Company Country -->
        <span
          v-if="props.column.field === 'company_country'"
          class="text-nowrap"
        >
          {{ (props.row.company_city.state.country) ? props.row.company_city.state.country.name : ''  }}
        </span>

        <!-- Column: Company City -->
        <span
          v-if="props.column.field === 'company_city_name'"
          class="text-nowrap"
        >
          {{ (props.row.company_city) ? props.row.company_city.name : ''  }}
        </span>

        <!-- Column: Company Province -->
        <span
          v-if="props.column.field === 'company_state'"
          class="text-nowrap"
        >
          {{ (props.row.company_city.state) ? props.row.company_city.state.name : ''  }}
        </span>

        <!-- Column: Office Phone -->
        <span
          v-if="props.column.field === 'company_phone'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Office Email -->
        <span
          v-if="props.column.field === 'company_email'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Position -->
        <span
          v-if="props.column.field === 'position'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Job Level -->
        <span
          v-if="props.column.field === 'job_level'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'created_at'"
          class="text-nowrap"
        >{{ props.formattedRow[props.column.field] }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Home Address',
          field: 'home_address',
        },
        {
          label: 'Company Name',
          field: 'company',
        },
        {
          label: 'Brand Name',
          field: 'brand_name',
        },
        {
          label: 'Company Industry',
          field: 'industry',
        },
        {
          label: 'Company Size',
          field: 'company_size',
        },
        {
          label: 'Company Address',
          field: 'company_address',
        },
        {
          label: 'Company Country',
          field: 'company_country',
        },
        {
          label: 'Company City',
          field: 'company_city_name',
        },
        {
          label: 'Company Province',
          field: 'company_state',
        },
        {
          label: 'Office Phone',
          field: 'company_phone',
        },
        {
          label: 'Office Email',
          field: 'company_email',
        },
        {
          label: 'Job Title',
          field: 'position',
        },
        {
          label: 'Submitted At',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        }
      ],
      searchTermProfile: '',
    }
  },
}
</script>